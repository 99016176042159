<template>
  <div class="mdgl">
    <div class="head">
      <van-search class="search" v-model="value" shape="round" background="#3fb6c6" placeholder="请输入" @search="onSearch">
      </van-search>
      <div class="searchBtn" @click="handleClickMenu">
        <img class="searchBtn-img" src="@/assets/img/sarchbtn.png" alt="">
      </div>
    </div>
    <router-view />
    <van-action-sheet v-model:show="showShare" cancel-text="取消" close-on-click-action :actions="options"
      @select="handleSelect" />
  </div>
</template>

<script>
// @ is an alias to /src

import axios from 'axios';
import { showConfirmDialog, showNotify, showToast } from 'vant';
import { mapActions, mapState } from 'vuex';

export default {
  name: "Mdgl",
  data() {
    return {
      showShare: false,
      value: "",
      currentMenu: '',
      store: null,
    };
  },
  created() {
    // this.getStore();
    this.value = this.$route.query.search;
    this.get_user_detail({
      sjhm:this.$route.query.sjhm
    });
  },
  computed: {
    ...mapState(['user_detail']),
    options() {
      if (this.user_detail) {
        if (this.user_detail.mdgl == '1') {
          return [
            {
              name: "会员指标",
            },
            {
              name: "销售管理",
            },
            {
              name: "提货管理",
            },
            {
              name: "积分管理",
            },
            {
              name: "套餐管理",
            },
            {
              name: "商品管理",
            },
          ]
        }
        else {
          return [
            {
              name: "会员指标",
            },
            {
              name: "销售管理",
            },
            {
              name: "提货管理",
            }
          ]
        }
      }
      return [];
    }
  },
  methods: {
    ...mapActions(['get_user_detail']),
    onSearch(val) {
      this.value = val;
      this.handleSelect(this.currentMenu, val);
    },
    handleClickMenu() {
      this.showShare = true;
    },
    handleSelect(val, search = '') {
      // console.log(val.name);
      let path = '';
      if (val.name == '销售管理') {
        path = '/mdgl/xiaoshou';
      }
      else if (val.name == '会员指标') {
        path = '/mdgl/user_zhibiao';
      }
      else if (val.name == '提货管理') {
        path = '/mdgl/tihuo';
      }
      else if (val.name == '积分管理') {
        path = '/mdgl/jifen';
      }
      else if (val.name == '套餐管理') {
        path = '/mdgl/taocan';
      }
      else if (val.name == '商品管理') {
        path = '/mdgl/shangpin';
      }
      this.currentMenu = val;
      this.$router.push({
        path: path,
        query: {
          sjhm: this.$route.query.sjhm,
          sfbs: this.$route.query.sfbs,
          search
        }
      })
      this.showShare = false;
    },
    // async getStore() {
    //   let { data: res } = await axios.get("/api/api/get_guanli_mendian", {
    //     params: {
    //       sjhm: this.$route.query.sjhm
    //     }
    //   });
    //   this.store = res.data?.[0] || null;
    // },
  },
  components: {}
}
</script>
<style lang="less" scoped>
.mdgl {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  .head {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    background: #3fb6c6;
    padding-left: 12px;

    .search {
      flex: 1;
    }

    .searchBtn {
      width: 32px;
      margin-right: 12px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:active {
        opacity: .8;
      }

      .searchBtn-img {
        width: 24px;
      }
    }
  }
}
</style>
